import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isNil } from 'lodash';

import { LocalStorageService } from 'ngx-webstorage';
import { QueryParams } from './filter';

export interface BaseHeaders {
  headers: HttpHeaders;
}

export interface Res<T> {
  numReg?: number;
  res?: T;
}

export interface Base {
  id: string;
}

export class EntityService<T extends Base> {

  private _filter: QueryParams;

  constructor(private http: HttpClient, private url: string, private headers: any) {
    this._filter = new QueryParams();
  }

  findById(id: string, subR?: any, where?: any): Observable<T> {
    return this.http.get<T>
      (`${this.url}/${id}${isNil(subR) ? '' : `/${subR}`}${isNil(where) && this._filter.count === 0 ? '' :
        `?${this._filter.convert(where)}`}`, this.headers() as BaseHeaders);
  }

  find(where?: any, subR?: any): Observable<T[]> {
    return this.http.get<T[]>(`${this.url}${isNil(subR) ? '' :
      `/${subR}`}${isNil(where) && this._filter.count === 0 ? '' : `?${this._filter.convert(where)}`}`,
      this.headers() as BaseHeaders);
  }

  insert(obj: T): Observable<T> {
    return this.http.post<T>(`${this.url}`, obj, this.headers() as BaseHeaders);
  }

  post(subRecurso?: any): Observable<T> {
    return this.http.post<T>(`${this.url}${isNil(subRecurso) ? '' :
      `/${subRecurso}`}`, null, this.headers() as BaseHeaders);
  }

  update(obj: T, where?: any): Observable<T> {
    return this.http.put<T>(`${this.url}/${obj.id}${isNil(where) && this._filter.count === 0 ? '' :
      `?${this._filter.convert(where)}`}`, obj, this.headers() as BaseHeaders);
  }

  put(obj: T, subRecurso?: any, where?: any): Observable<T> {
    return this.http.put<T>(`${this.url}/${obj.id}${isNil(subRecurso) ? '' :
      `/${subRecurso}`}${isNil(where) && this._filter.count === 0 ? '' :
        `?${this._filter.convert(where)}`}`, obj, this.headers() as BaseHeaders);
  }

  delete(obj: T): Observable<T> {
    return this.http.delete<T>(`${this.url}/${obj.id}`, this.headers() as BaseHeaders);
  }

  getPdf(id: string, body: any): Observable<Blob> {
    return this.http.post<Blob>(`${this.url}/${id}/imprimir`, body, {headers: new HttpHeaders({
      'Accept': 'application/pdf',
    }), responseType: 'blob' as 'json'});
  }

  get queryParams(): QueryParams {
    return this._filter;
  }
}

export class EntityServiceFactory {
  static create<T>(http: HttpClient, url: string, headers: any): EntityService<any> {
    return new EntityService<any>(http, url, headers);
  }
}

export class ResourceApi {
  private api: Map<string, EntityService<any>> = new Map<string, EntityService<any>>();
  private headers: any;
  constructor(private http: HttpClient, private storage: LocalStorageService, private url: string) {
    this.headers = (): { headers: HttpHeaders } => {
      return { headers: new HttpHeaders() };
    };

    this.headers = (): { headers: HttpHeaders } => {
      const headers: any = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
        'Access-Control-Allow-Headers':
        `Content-Type, Depth, User-Agent, X-File-Size, X-Requested-With, If-Modified-Since, X-File-Name, Cache-Control`,
        'Content-Type': 'application/json',
        Authorization: this.storage.retrieve('token') || '',
      };
      return { headers: new HttpHeaders(headers) };
    };
  }
  add<T>(key: string, resource: string): EntityService<any> {
    const rsc = EntityServiceFactory.create<T>(this.http, `${this.url}${resource}`, this.headers);
    this.api.set(key, rsc);
    return rsc;
  }

  get<T>(key: string): EntityService<any> {
    const rsc = this.api.get(key);
    return rsc;
  }

  remove(key: string): boolean {
    return this.api.delete(key);
  }
}
