declare global {
  interface Date {
    fechaNormal(separator: string): string;
    fechaHoraNormal(separator: string): string;
    inicioMes(): Date;
    finMes(): Date;
    inicioDia(): Date;
  }
  interface Number {
    round(precision: number): number;
    formatoMoneda(simbolo: string): string;
  }
}

export function fechaNormal(date: Date, separator: string): string {
  if ((date.valueOf() - (date.getTimezoneOffset() * 60 * 1000)) === 0) {
    return '';
  }
  return (((date.getDate() < 10 ? '0' : '') + date.getDate()) +
    separator + (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)
    + separator + (date.getFullYear())).replace(/ /g, '');
}

export function fechaHoraNormal(date: Date, separator: string): string {
  if ((date.valueOf() - (date.getTimezoneOffset() * 60 * 1000)) === 0) {
    return '';
  }
  return (((date.getDate() < 10 ? '0' : '') + date.getDate()) +
    separator + (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)
    + separator + (date.getFullYear())).replace(/ /g, '') + ' ' + date.getHours() + ':' +
    (date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes() );
}

Date.prototype.fechaNormal = function (separator: string): string {
  return fechaNormal(this, separator);
};

Date.prototype.fechaHoraNormal = function (separator: string): string {
  return fechaHoraNormal(this, separator);
};

Date.prototype.inicioMes = function (): Date {
  return new Date(this.getFullYear(), this.getMonth(), 1);
};

Date.prototype.finMes = function (): Date {
  return new Date(this.getFullYear(), this.getMonth() + 1, 0);
};

Date.prototype.inicioDia = function (): Date {
  const date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  return date;
};

export function round(number: number, precision: number): number {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}

export function formatoMoneda(valor: number, simbolo: string): string {
  return simbolo + '.' + (Number.isInteger(valor) ? new Intl.NumberFormat('en-IN').format(valor) + '.00' :
    new Intl.NumberFormat('en-IN').format(valor));
}

Number.prototype.round = function (precision: number): number {
  return round(this as number, precision);
};

Number.prototype.formatoMoneda = function (simbolo: string): string {
  return formatoMoneda(this as number, simbolo);
};
