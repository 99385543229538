import { Injectable } from '@angular/core';
import { CoreService } from '../@core/services/core.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private _core: CoreService) { }
  resolve(): Promise<any> {
    this._core.api.add<any>('login', 'login');
    return Promise.resolve();
  }
}
