import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { EcommerceService } from './pages/e-commerce/e-commerce.service';
import { AuthService } from './auth/auth.service';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: './auth/auth.module#NgxAuthModule',
    resolve: {
      service: AuthService,
    },
  },
  {
    path: 'pages',
    loadChildren: './pages/pages.module#PagesModule',
    resolve: {
      service: EcommerceService,
    },
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
