export interface FilterRsc {
    name: string;
    value: number | string | boolean;
    like?: boolean;
    encode?: boolean;
    in?: boolean;
    sql?: boolean;
  }
  export class QueryParams {

    private _filters: FilterRsc[] = [];

    constructor() { }

    add(filter: FilterRsc) {
      const index = this._filters.findIndex(value => value.name === filter.name);
      if (index >= 0) {
        this._filters[index].value = filter.value;
        this._filters[index].like = filter.like;
        this._filters[index].encode = filter.encode;
        this._filters[index].in = filter.in;
        this._filters[index].sql = filter.sql;
      } else {
        this._filters.push(filter);
      }
    }

    remove(name: string) {
      this._filters.splice(this._filters.findIndex(value => value.name === name), 1);
    }

    clean() {
      this._filters.length = 0;
    }

    convert(where: string) {
      if (this._filters.length > 0) {
        this._filters.forEach( value => {
          if (where) {
            where += '&';
          } else {
            where = '';
          }
          if (value.like && !value.encode) {
            value.value = '__like__' + value.value;
          }
          if (value.in && !value.encode) {
            value.value = '__multiple__' + value.value;
          }
          if (value.encode) {
            value.value = '__encode__' + `${value.like ? '__like__' : value.in ? '__multiple__' : ''}`
              + btoa(value.value.toString());
          }
          where += `${value.name}=${value.value}`;
          /*if (value.sql) {
            if (value.like) {
              value.value = '%' + value.value + '%';
            }
            if (value.in) {
              value.name = value.name + '__in';
            }
            if (value.encode) {
              value.value = 'b64:' + btoa(value.value.toString());
            }
            where += `${value.name}=${value.value}`;
          } else {
            where += `${value.name + (value.like ?  '__icontains'  :
            value.in ? '__in' : '') + '=' + (value.encode ? 'b64:' +
              btoa(value.value.toString()) : value.value)}`;
          }*/
        });
      }
      return where;
    }

    get count(): number {
      return this._filters.length;
    }
  }
