import { Injectable } from '@angular/core';
import { CoreService } from '../../@core/services/core.service';

@Injectable({
  providedIn: 'root',
})
export class EcommerceService {

  constructor(private _core: CoreService) { }
  resolve(): Promise<any> {
    this._core.api.add<any>('query', 'consultas');
    this._core.api.add<any>('unidad-medida', 'unidad-medida');
    this._core.api.add<any>('cliente', 'cliente');
    this._core.api.add<any>('usuario', 'usuario');
    this._core.api.add<any>('menu', 'menu');
    this._core.api.add<any>('inventario', 'inventario');
    this._core.api.add<any>('bodega', 'bodega');
    this._core.api.add<any>('ubicacion', 'ubicacion');
    this._core.api.add<any>('route', 'ruta');
    this._core.api.add<any>('agrupador-mov', 'agrupador-mov');
    this._core.api.add<any>('secuencia', 'secuencia');
    this._core.api.add<any>('tipo-movimiento', 'tipo-movimiento');
    this._core.api.add<any>('metodo-pago', 'metodo-pago');
    this._core.api.add<any>('forma-pago', 'forma-pago');
    this._core.api.add<any>('impuesto', 'impuesto');
    this._core.api.add<any>('conversion-medida', 'conversion-medida');
    this._core.api.add<any>('configuracion', 'configuracion');
    this._core.api.add<any>('matriz-descuentos', 'matriz-descuentos');
    this._core.api.add<any>('producto', 'producto');
    this._core.api.add<any>('lista-precio', 'lista-precio');
    this._core.api.add<any>('documento-inv', 'documento-inv');
    this._core.api.add<any>('traslado', 'traslado');
    return Promise.resolve();
  }
}
